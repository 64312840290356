import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavigationBar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import PostImage from './pages/PostImage'; // Import the PostImage component
// import DonateButton from './components/DonateButton';

const App: React.FC = () => {

  const appStyle = {
    backgroundImage: 'url(/path/to/your/background-image.jpg)', // Replace with your image path
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh', // Ensure it covers the full viewport height
  };

  return (
    <Router>
      <div className="d-flex flex-column min-vh-100" style={appStyle}>
        <NavigationBar />
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/post-image" element={<PostImage />} /> {/* Use the imported PostImage component */}
        </Routes>
        <Footer />
        {/* <DonateButton /> */}
      </div>
    </Router>
  );
}

export default App;
