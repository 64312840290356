// import example1Img from '../assets/images/example1.jpg';
// import example2Img from '../assets/images/example2.jpg';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import PostImage from './PostImage'; // Import the PostImage component
import { Image } from '../types/imageTypes';
import './Home.css';
 import defaultValue from '../components/data.json';

const Home = () => {

    const [images, setImages] = useState<Image[]>([]);

  useEffect(() => {
    // Function to fetch images
    const fetchImages = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/images`);
      const data = await response.json();
      data.length > 0 ? setImages(data) :
      setImages(defaultValue);
    };

    fetchImages();
  }, []);

    return (
        <>
        <div>
            <Row className='mb-5 '>
                    <Col  className="col-12" style={{ maxHeight: '50% !important' }}>
                        <Card className="shadow-sm top-img" style={{ maxHeight: '50% !important' }}>
                            <Card.Img  style={{ maxHeight: '50% !important' }} variant="top" src="https://i.ibb.co/Tq8RrwD/home-page-1.jpg" />
                        </Card>
                    </Col>
            </Row>
        </div>
        <Container className='fh5co-cover text-center' style={{ backgroundImage: 'url("images/cover_bg_1.jpg")', backgroundPosition: '0px -67.4px' }}
        >
            
            

                {images.map((image, index) => (
            <Row className='mt-5 '>
                    <Col key={index} className="col-12">
                        <Card className="shadow-sm">
                        {image.title
                            && <Card.Title className='card-post-title'>{image.title}</Card.Title>}
                            <Card.Img variant="top" src={image.src} />
                            <Card.Text className='card-post-text' dangerouslySetInnerHTML={{ __html: image.description }}></Card.Text>
                        </Card>
                    </Col>
            </Row>
                ))}
        </Container>
        </>
    );
};

export default Home;