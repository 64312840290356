import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import { Image } from "../types/imageTypes";
import "./PostImage.css";
import { PencilSquare, Trash } from "react-bootstrap-icons";

const PostImage = () => {
  const [images, setImages] = useState<Image[]>([]);
  const [imageUrl, setImageUrl] = useState("");
  const [description, setDescription] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editingImageId, setEditingImageId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false); // Add a loading state
  const [title, setTitle] = useState("");

  useEffect(() => {
    // Fetch images from your API or server
    setIsLoading(true);
    const fetchImages = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/images`);
      const data = await response.json();
      setImages(data);
      setIsLoading(false); // End loading
    };

    fetchImages();
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newImage = { src: imageUrl, description, title };
    setIsLoading(true);
    if (isEditing && editingImageId) {
      // Edit image
      await fetch(`${process.env.REACT_APP_API_URL}/${editingImageId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(newImage)
      });
      setIsEditing(false);
      setEditingImageId(null);
    } else {
      // Post new image
      await fetch(`${process.env.REACT_APP_API_URL}/post-image`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(newImage)
      });
    }

    setImageUrl("");
    setDescription("");
    setTitle("");
    setIsLoading(false);
    // Optionally, refresh the images list
  };

  
  const handleEdit = (img: Image) => {
    setImageUrl(img.src);
    setDescription(img.description);
    setTitle(img.title || "");
    setIsEditing(true);
    setEditingImageId(img.id);
  };

  const handleDelete = async (id: string) => {
    await fetch(`${process.env.REACT_APP_API_URL}/delete-image/${id}`, {
      method: "DELETE"
    });

    // Optionally, refresh the images list here
  };
  return (
    <Container className="mt-4">
      {isLoading && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formImageUrl" className="mt-4">
          <Form.Label>Image URL</Form.Label>
          <Form.Control
            className="form-field"
            type="text"
            placeholder="Enter image URL"
            value={imageUrl}
            onChange={e => setImageUrl(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formTitle" className="mt-4">
          <Form.Label>Title</Form.Label>
          <Form.Control
            className="form-field"
            type="text"
            placeholder="Enter image URL"
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
        </Form.Group>
        {/* <Form.Group controlId="formPlaintextTitle" className="mt-4">
          <Form.Label>Title</Form.Label>
          <Form.Control type="text" placeholder="Image Title" value={title} onChange={e => setTitle(e.target.value)} />
        </Form.Group> */}
        <Form.Group controlId="formDescription" className="mt-4">
          <Form.Label>Description</Form.Label>
          <Form.Control
            className="form-field" // Apply the CSS class
            placeholder="Enter description"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-4">
          {isEditing ? "Save" : "Post"}
        </Button>
        {isEditing && (
          <Button
            variant="secondary"
            className="mt-4"
            onClick={() => {
              setIsEditing(false);
              setEditingImageId(null);
              setImageUrl("");
              setDescription("");
            }}
          >
            Cancel
          </Button>
        )}
      </Form>
      <Container className="mt-4">
        <Row className="mb-2 bordered-row">
          <Col className="col-md-2 bordered-col">#</Col>
          <Col className="column-max-width bordered-col">Image URL</Col>
          <Col className="column-max-width bordered-col">Description</Col>
          <Col className="bordered-col col-md-2">Action</Col>
        </Row>
        {images.map((image, index) => (
          <Row key={index} className="mb-1 bordered-row">
            <Col className="col-md-2 bordered-col">{index + 1}</Col>
            <Col className="text-overflow-ellipsis bordered-col">{image.src}</Col>
            <Col className="text-overflow-ellipsis bordered-col">{image.description}</Col>
            <Col className="bordered-col col-md-2">
              <Button variant="link" onClick={() => handleEdit(image)}>
                <PencilSquare size={20} /> {/* Use the desired size */}
              </Button>{" "}
              <Button variant="link" onClick={() => handleDelete(image.id)}>
                <Trash size={20} /> {/* Use the desired size */}
              </Button>
            </Col>
          </Row>
        ))}
      </Container>
    </Container>
  );
};

export default PostImage;
