import React, { useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import "./AboutUs.css";

const AboutUs: React.FC = () => {
  const [content, setContent] = useState<string>("");
  const [activeKey, setActiveKey] = useState<string | null>(null); // State to track the active content key

  const handleButtonClick = (key: string, contentValue: string) => {
    setContent(contentValue);
    setActiveKey(key); // Update the active key
  };

  useEffect(() => {
    setContent(contentMap["Who We Are"]);
    setActiveKey("Who We Are");
  }, []);
  const contentMap = {
    'Who We Are': `Nan Sei Foundation is a non-profit organization dedicated to serving underprivileged communities in Tamil Nadu. The organization was started by a group of young and passionate individuals who wanted to make a positive impact in the lives of marginalized communities.

The foundation recognizes that young people have a vital role to play in creating positive change and empowering communities. Therefore, it has focused on engaging and empowering young people to be active agents of change in their communities.

Nan Sei Foundation's vision is to create a society where everyone has access to basic amenities such as clean water, education, healthcare, and livelihood opportunities. The organization works towards achieving this vision through a range of programs and initiatives that address the most pressing needs of underprivileged communities.

Some of the key areas of focus for the organization include education, healthcare, women's empowerment, environmental sustainability, and community development. Nan Sei Foundation has implemented a range of projects and initiatives in these areas, working closely with communities to identify their needs and challenges and develop sustainable solutions.
`,
    'Mission': `Nan Sei Foundation's mission is to improve the quality of life of marginalized communities in Tamil Nadu by providing them with access to basic amenities such as clean water, education, healthcare, and livelihood opportunities. The organization is committed to working with communities to identify their needs and challenges and developing sustainable solutions that empower them to lead healthy, productive, and fulfilling lives.
`,
    'Vision': `Nan Sei Foundation's vision is to create a society where everyone has access to basic amenities and opportunities, regardless of their background or circumstances. The organization believes that a just and equitable society can only be achieved by empowering marginalized communities and creating opportunities for them to participate fully in the social, economic, and political life of the country. Nan Sei Foundation envisions a future where every individual has the resources and support they need to realize their full potential and lead a dignified life.
`,
    'Objectives': `1. To provide livelihood programs to reduce poverty and inequality by generating employment among poor households and moving highly vulnerable households into sustainable livelihoods and economic stability, irrespective of their race, caste, creed, gender, community, etc.

2. To facilitate the realization of equal opportunities, protection of rights, and full participation of persons with disabilities, supporting registered organizations to provide need-based services, and enabling persons with disabilities to live as independently and fully as possible within their communities.

3. To provide relief to the poor by offering financial or other assistance in the form of books, notebooks, clothes, uniforms, or meals, and aid to those affected by natural calamities.

4. To maintain or assist medical institutions, nursing homes, or clinics, and to grant assistance to needy individuals for medical treatment. To organize medical camps in public places.

5. To maintain or assist educational institutions, offer coaching, guidance, counseling, or vocational training, and provide scholarships for deserving and needy students for elementary and higher education.

6. To provide medical facilities and healthcare services to the elderly, offer shelter and clothing for residents of old age homes, provide nutritious food, and create peaceful spaces for senior citizens.

7. To provide equal opportunities for physically challenged persons, supporting their full participation in society, offering financial assistance to similar organizations, and encouraging skill development and livelihood projects.

8. To create and develop programs for women’s and child welfare, ensuring overall development.

9. To eradicate social evils like child marriage, child labor, child abuse, and women’s issues, and create awareness in these fields.

10. To create a clean and hygienic environment, inculcate values of cleanliness and hygiene, and create ambassadors of change.

11. To provide knowledge that benefits transgender people and communities.

12. To organize donation camps and spread social awareness among youth.

13. To organize programs for climate change mitigation, including greening initiatives, solid waste management, and recycling/reuse techniques.

14. To prevent cruelty against animals, secure their welfare, and build and maintain shelters and rescue homes.

15. To provide athletic training for children and youth, promoting mental and physical health and essential life skills like leadership, teamwork, communication, and positive thinking.

16. To undertake activities for general public utility, including acquiring property for public use, conducting educational and empowerment programs, and organizing special events.
`
  };

  return (
    <Container className="mt-4">
      <h1>About Us</h1>
      <div className="button-container">
        {Object.keys(contentMap).map(key => (
          <Button
            key={key}
            variant="link"
            className={key === activeKey ? 'active' : ''} // Apply 'active' class if the key matches the activeKey
            onClick={() => handleButtonClick(key, contentMap[key as keyof typeof contentMap])}
          >
            {key}
          </Button>
        ))}
      </div>
      <div className="content-section">
        {content.split("\n\n").map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
    </Container>
  );
};

export default AboutUs;
