import React from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap';
import { FaHome, FaInfo, FaEnvelope, FaImage } from 'react-icons/fa';
import './Navbar.css'; // Assuming you have a Navbar.css file for custom styles

const NavigationBar: React.FC = () => {
  return (
    <>
    <Row>
    <Col className="header-top">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6 text-left fh5co-link">
              <a href="#">FAQ</a>
              <a href="#">Forum</a>
              <a href="#">Contact</a>
            </div>
            <div className="col-md-6 col-sm-6 text-right fh5co-social">
              <a href="#" className="grow"><i className="icon-facebook2"></i></a>
              <a href="#" className="grow"><i className="icon-twitter2"></i></a>
              <a href="#" className="grow"><i className="icon-instagram2"></i></a>
            </div>
          </div>
        </div>
      </Col>
      </Row>
      <Row>
    <Col>
    <Navbar expand="lg" className="custom-navbar mb-0">
      <Container className='p-1'>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="fh5co-menu-wrap"  className='mb-0'>
          <Nav id='fh5co-primary-menu' className='ml-auto mb-0'>
            <NavLink to="/"><FaHome /> Home</NavLink>
            <NavLink to="/about-us" ><FaInfo /> About Us</NavLink>
            <NavLink to="/contact-us" ><FaEnvelope /> Contact Us</NavLink>
            <NavLink to="/post-image" ><FaImage /> Post</NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </Col>
    </Row>
    <Row >
      <Col className='row-logo'>
  <img
    src="https://i.ibb.co/x8CgP70/Nan-Sei-Png.png"
    className="fh5co-logo na-logo-png"
    alt="Nan Sei Foundation Logo"
  />
      {/* </Col>
      <Col> */}
      <Navbar id='fh5co-logo' className='na-logo'>
          Nan Sei Foundation
        </Navbar>
      </Col>
      </Row>
    </>
  );
}

export default NavigationBar;