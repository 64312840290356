import React from 'react';

const Footer = () => {
  return (
    <footer>
      <div id="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-md-offset-3 text-center">
              {/* Social icons - You can add your own icons and links here */}
              <p className="fh5co-social-icons">
                <a href="#"><i className="icon-twitter2"></i></a>
                <a href="#"><i className="icon-facebook2"></i></a>
                <a href="#"><i className="icon-instagram"></i></a>
                <a href="#"><i className="icon-dribbble2"></i></a>
                <a href="#"><i className="icon-youtube"></i></a>
              </p>
              {/* Copyright text */}
              <p>
                &copy; 2024 NanSai Foundation. All Rights Reserved.
                <br />
                Made with <i className="icon-heart3"></i> by <a href="#" target="_blank" rel="noopener noreferrer">PPI</a> / Demo Images: <a href="https://unsplash.com/" target="_blank" rel="noopener noreferrer">Unsplash</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
